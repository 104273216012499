// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/App.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-contact-form-tsx": () => import("./../../../src/pages/ContactForm.tsx" /* webpackChunkName: "component---src-pages-contact-form-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cooperation-tsx": () => import("./../../../src/pages/Cooperation.tsx" /* webpackChunkName: "component---src-pages-cooperation-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/Home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-layout-tsx": () => import("./../../../src/pages/Layout.tsx" /* webpackChunkName: "component---src-pages-layout-tsx" */),
  "component---src-pages-offer-tsx": () => import("./../../../src/pages/Offer.tsx" /* webpackChunkName: "component---src-pages-offer-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/Portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/Privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

